import Web3 from 'web3';
import pacaABI from './paca_ABI.json';  // Import the ABI

// Binance Smart Chain connection URL
const BSC_URL = 'https://bsc-dataseed.binance.org/';
const web3 = new Web3(new Web3.providers.HttpProvider(BSC_URL));

// Smart contract details
const contractAddress = '0x30D22DA999f201666fB94F09aedCA24419822e5C';  // Your contract address

// Wallet address
const walletAddress = '0xfa71C8000CE627c30fDC50ae861F28fD692915A3';

// Fetch the stakes from the contract
export const fetchStakesAndClaimable = async () => {
  try {
    const contract = new web3.eth.Contract(pacaABI, contractAddress);  // Use the imported ABI
    const tokenPrice = 1;  // Assuming USDT pegged to $1

    // Fetch stakes for the wallet
    const stakes = await contract.methods.getStakes(walletAddress).call();
    
    // Ensure stakes exist
    if (!stakes || stakes.length === 0) {
      return {
        combinedStakeValueUSD: 0,
        claimableUSD: 0
      };
    }

    // Calculate combined value of all stakes in USD
    const combinedStakeValueUSD = stakes.reduce((total, stake) => {
      const amountStaked = web3.utils.fromWei(stake[0], 'ether');  // stake[0] is the staked amount in wei
      return total + (parseFloat(amountStaked) * tokenPrice);
    }, 0);

    // Fetch claimable rewards
    const claimableRewards = await contract.methods.viewAllRewards(walletAddress).call();
    const claimableUSD = parseFloat(web3.utils.fromWei(claimableRewards, 'ether')) * tokenPrice;

    return {
      combinedStakeValueUSD: combinedStakeValueUSD.toFixed(2),
      claimableUSD: claimableUSD.toFixed(2)
    };
  } catch (error) {
    console.error('Error fetching stakes or rewards:', error);
    return {
      combinedStakeValueUSD: 0,
      claimableUSD: 0
    };
  }
};