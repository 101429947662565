import React, { useEffect, useState } from "react";
import ArbitrumLand from "./ArbitrumLand";
import Castle from "./elements/Castle";
import Dragons from "./elements/Dragons";
import Tower from "./elements/Tower";
import Portal from "./elements/Portal";
import {
  ImgOutline,
  IndexContainer
} from "../themes/global";
import Footer from "./footer/Footer";
import Header from "./header/Header";

const Index = () => {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [showFooter, setShowFooter] = useState(true);
  const [showHeader, setShowHeader] = useState(true);

  const [showCastle, setShowCastle] = useState(false);
  const [showDragons, setShowDragons] = useState(false);
  const [showPortal, setShowPortal] = useState(false);
  const [showTower, setShowTower] = useState(false);

  const closeHeaderFooter = () => {
    if (windowSize.width < 1024) {
      return;
    }
    setShowHeader(false);
    setShowFooter(false);
  }
  const openHeaderFooter = () => {
    if (windowSize.width < 1024) {
      return;
    }
    setShowHeader(true);
    setShowFooter(true);
  }

  const handleCastleClick = () => {
    setShowCastle(!showCastle);
    closeHeaderFooter();
  }

  const closeCastle = () => {
    setShowCastle(false);
    openHeaderFooter();
  }

  const closeDragons = () => {
    setShowDragons(false);
    openHeaderFooter();
  }

  const handleDragonClick = () => {
    setShowDragons(!showDragons);
    closeHeaderFooter();
  }

  const handleMineClick = () => {
    window.open("https://polygonland.club?ref=0xfa71c8000ce627c30fdc50ae861f28fd692915a3", "_blank");
    closeHeaderFooter();
  }

  const handlePortalClick = () => {
    setShowPortal(!showPortal);
    closeHeaderFooter();
  }

  const closeTower = () => {
    setShowTower(false);
    openHeaderFooter();
  }

  const handleSpiderClick = () => {
    window.open("https://cryptomischief.io/scc-nft/", "_blank");
  }

  const handleTowerClick = () => {
    setShowTower(!showTower);
    closeHeaderFooter();
  }

  const handleUfoClick = () => {
    window.open("https://cryptomischief.io/wp-content/uploads/Arbitrum-Land-WP.pdf", "_blank");
  }

  const closePortal = () => {
    setShowPortal(false);
    openHeaderFooter();
  }

  const onFooterClick = () => {
    setShowFooter(!showFooter);
  }

  const onHeaderClick = () => {
    if (windowSize.width < 1024) {
      return;
    }
    setShowHeader(!showHeader);
  }

  return (
    <div className="index">
      <IndexContainer>
        <Header
          cssClass={showHeader ? "header-opened" : "header-collapsed"}
          toggleState={onHeaderClick}
        />
        <ArbitrumLand
          onCastleClick={handleCastleClick}
          onDragonClick={handleDragonClick}
          onPortalClick={handlePortalClick}
          onTowerClick={handleTowerClick}
          onMineClick={handleMineClick}
          onSpiderClick={handleSpiderClick}
          onUfoClick={handleUfoClick}
        />
        {showCastle ? <Castle closeModal={closeCastle} /> : null}
        {showDragons ? <Dragons closeModal={closeDragons} /> : null}
        {showTower ? <Tower closeModal={closeTower} /> : null}
        {showPortal ? <Portal closeModal={closePortal} /> : null}

        <div className="button-container"> {/* New Flexbox container */}
          <ImgOutline onClick={handleCastleClick}>
            <div className="infobox-title">
              <p>Castle Arb</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handleDragonClick}>
            <div className="infobox-title">
              <p>Compound</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handlePortalClick}>
            <div className="infobox-title">
              <p>Claim</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handleTowerClick}>
            <div className="infobox-title">
              <p>Boost APR</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handleMineClick}>
            <div className="infobox-title">
              <p>Polygon Land</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handleSpiderClick} display="none">
            <div className="infobox-title">
              <p>Mint NFT</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handleUfoClick} display="none">
            <div className="infobox-title">
              <p>Contract</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handleUfoClick} display="none">
            <div className="infobox-title">
              <p>Tutorial</p>
            </div>
          </ImgOutline>
        </div>

        {/* Embed YouTube video */}
        <div className="youtube-video" style={{ marginTop: '80px' }}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/jwn7PC7pvKI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <Footer
          cssClass={showFooter ? "footer-opened" : "footer-collapsed"}
          toggleState={onFooterClick}
          handleDragonClick={handleDragonClick}
          handlePortalClick={handlePortalClick}
          onCastleClick={handleCastleClick}
        />
      </IndexContainer>
    </div>
  )
}

export default Index;