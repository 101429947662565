import React, { useState, useEffect } from "react";
import Modal from "../shared/Modal";
import { InfoBox, InfoBoxContainer } from "../../themes/global";
import { FaCopy } from 'react-icons/fa';
import ShortenLink from '../ShortenLink';

const Castle = ({ closeModal }) => {

  useEffect(() => {
    document.body.classList.add('castle-modal-open');
    return () => {
      document.body.classList.remove('castle-modal-open');
    };
  }, []);

  const close = () => {
    window.clearAllIntervals();
    closeModal();
  };

  let refAddress = "0x0000000000000000000000000000000000000000";

  const [depositCooldown, setDepositCooldown] = useState(0);
  const [actionCooldown, setActionCooldown] = useState(0);
  const [referralRoyalties, setReferralRoyalties] = useState(0);
  const [availableRewards, setAvailableRewards] = useState(0);
  const [availableRewardsInEth, setAvailableRewardsInEth] = useState(0);
  const [userDeposits, setUserDeposits] = useState(0);
  const [getTotalDeposits, setTotalDeposits] = useState(0);  // State to hold TVL
  const [maxPayoutReached, setMaxPayoutReached] = useState(false);
  const [contractHasStarted, setContractHasStarted] = useState(false);
  const [totalPayout, setTotalPayout] = useState(0);
  const [miners, setMiners] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [minDeposit, setMinDeposit] = useState("0.003");
  const [referralTier, setReferralTier] = useState(null);
  const [refLink, setRefLink] = useState(`${window.location.origin}?ref=${window.currentAddr}`);

  const calculateDepositCD = (lastAction, contractDepositCD) => {
    const now = Math.floor(new Date().getTime() / 1000);
    let timeLeft = lastAction + contractDepositCD - now;
    timeLeft = (timeLeft < 0 ? 0 : timeLeft) / 60 / 60;
    timeLeft = Math.round(timeLeft * 100) / 100;
    console.log("User Deposit CD", timeLeft);
    return timeLeft;
  };

  const startDepositCDCalculation = (lastAction, contractDepositCD) => {
    setInterval(() => {
      let timeLeft = calculateDepositCD(lastAction, contractDepositCD);
      setDepositCooldown(timeLeft);
    }, 1000);
  };

  const calculateActionCD = (lastAction, actionCoolDown) => {
    const now = Math.floor(new Date().getTime() / 1000);
    let timeLeft = lastAction + actionCoolDown - now;
    timeLeft = (timeLeft < 0 ? 0 : timeLeft) / 60 / 60;
    timeLeft = Math.round(timeLeft * 100) / 100;
    console.log("User Action CD", timeLeft);
    return timeLeft;
  };

  const startActionCDCalculation = (lastAction, contractActionCD) => {
    setInterval(() => {
      const timeLeft = calculateActionCD(lastAction, contractActionCD);
      setActionCooldown(timeLeft);
    }, 1000);
  };

  const copyToClipboard = async (textToCopy) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
          document.execCommand("copy");
        } catch (error) {
          console.error(error);
        } finally {
          textArea.remove();
        }
      }
      alert("Ref link copied!");
    } catch (error) {
      alert(`Error copying to clipboard: ${error}`);
    }
  };

  const updateStateValues = async (userInfo) => {
    if (window.hasValidWeb3Connection === true) {
      const minDepositReponse = await window.contract.methods.MIN_DEPOSIT().call();
      setMinDeposit(window.readableETH(minDepositReponse, 3));

      if (userInfo === undefined || userInfo === null) {
        return;
      }
      console.log("UserInfo:", userInfo);
      console.log("currentAddr:", window.currentAddr);
      if (window.currentAddr) {
        const contractStarted = await window.contract.methods.contractStarted().call();
        setContractHasStarted(contractStarted);

        const getmaxPayoutReached = await window.contract.methods.maxPayoutReached(window.currentAddr).call();
        if (getmaxPayoutReached) setMaxPayoutReached(getmaxPayoutReached);

        const rewards = await window.contract.methods.getAvailableRewards(window.currentAddr).call();
        rewards && setAvailableRewards(rewards);
        const rewardsInEth = await window.contract.methods.calculateSell(window.currentAddr).call();
        rewardsInEth && setAvailableRewardsInEth(window.readableETH(rewardsInEth, 5));

        const totalDeposits = await window.contract.methods.getUserInfo(window.currentAddr).call();
        totalDeposits && setUserDeposits(window.readableETH(totalDeposits._totalDeposits, 3));

        // Fetching the TVL (Total Value Locked)
        const siteInfo = await window.contract.methods.protocolTotalLocked().call();  // Using protocolTotalLocked() function
        if (siteInfo) {
          console.log("Protocol Total Locked:", siteInfo);
          setTotalDeposits(window.readableETH(siteInfo, 3));
        } else {
          console.log("Failed to fetch TVL");
        }

        const depositCoolDown = await window.contract.methods.DEPOSIT_COOLDOWN().call();
        let depositTimeLeft = calculateDepositCD(parseInt(userInfo._lastAction), parseInt(depositCoolDown));
        setDepositCooldown(depositTimeLeft);
        startDepositCDCalculation(parseInt(userInfo._lastAction), parseInt(depositCoolDown));

        const actionCoolDown = await window.contract.methods.ACTION_COOLDOWN().call();
        const actionTimeLeft = calculateActionCD(parseInt(userInfo._lastAction), parseInt(actionCoolDown));
        setActionCooldown(actionTimeLeft);
        startActionCDCalculation(parseInt(userInfo._lastAction), parseInt(actionCoolDown));

        userInfo?._totalWithdrawals && setTotalPayout(window.readableETH(userInfo._totalWithdrawals, 5));
        userInfo?._referralRewards && setReferralRoyalties(window.readableETH(userInfo._referralRewards, 5));
      }
      setMiners(userInfo._miners);

      const tier = await window.contract.methods.calcRefRewardTierValue(window.currentAddr).call();
      setReferralTier(tier);
      return userInfo;
    }
  };

  const deposit = async () => {
    setLoading(true);
    if (depositAmount <= 0 || depositAmount === "") {
      alert("Ya gotta deposit something, buddy.");
      return;
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let linkedRefAddress = params.ref;
    refAddress = linkedRefAddress && linkedRefAddress !== "" && linkedRefAddress !== null ? linkedRefAddress : refAddress;
    await window.deposit(depositAmount, refAddress);
    const userInfo = await window.getUserInfo();
    window.clearAllIntervals();
    await updateStateValues(userInfo);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    window.clearAllIntervals();
    (async () => {
      const userInfo = await window.getUserInfo();
      await updateStateValues(userInfo);
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <Modal closeModal={close} title={"Castle Arb"} subTitle={"YOUR JOURNEY STARTS HERE"}>
        <p className="refLink">
          MY REFERRAL LINK: <br />
          <span onClick={(e) => copyToClipboard(e.target?.innerText)}>
            {`${window.location.origin}?ref=${window.currentAddr}`}
          </span>
          <FaCopy
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            onClick={(e) => copyToClipboard(`${window.location.origin}?ref=${window.currentAddr}`)}
          />
        </p>
        <ShortenLink longUrl={`${window.location.origin}?ref=${window.currentAddr}`} />

        <div className="input-pair">
          <input
            disabled={loading || !window.hasValidWeb3Connection || depositCooldown > 0}
            type="number"
            placeholder={`${minDeposit} $ETH MIN`}
            onChange={(e) => setDepositAmount(e.target.value)}
          />
          <button
            disabled={loading || !window.hasValidWeb3Connection || depositCooldown > 0 || contractHasStarted === false}
            className="primary-button"
            onClick={deposit}
          >
            DEPOSIT $ETH
         
          </button>
        </div>

        <InfoBoxContainer>
          {!window.hasValidWeb3Connection && (
            <div className="warning-banner">
              {contractHasStarted ? 'PLEASE CONNECT YOUR WALLET TO VIEW DETAILS OF USER AND CONTRACT.' : `THE CONTRACT HASN'T OPENED UP FOR DEPOSITS YET.`}
            </div>
          )}

<InfoBox className="highlighted-info-box">
  <h3 className="info-box-text">REWARDS</h3>
  <div className="rewards-wrapper">
    <span className="info-box-text">{parseInt(availableRewards)?.toLocaleString()} KNIGHTS</span>
    <br />
    <span className="info-box-text">{availableRewardsInEth} $ETH</span>
  </div>
</InfoBox>
<InfoBox className="highlighted-info-box">
  <h3 className="info-box-text"> MY KNIGHTS</h3>
  <p className="info-box-text">{parseInt(miners)?.toLocaleString()}</p>
</InfoBox>
<InfoBox className="highlighted-info-box">
  <h3 className="info-box-text"> CLAIMED</h3>
  <p className="info-box-text">{totalPayout} $ETH</p>
</InfoBox>
<InfoBox>
  <h3 className="info-box-text">TVL</h3>
  <p className="info-box-text">{getTotalDeposits} $ETH</p>
</InfoBox>
<InfoBox>
  <h3 className="info-box-text">REFERRALS</h3>
  <p className="info-box-text">{referralRoyalties} $ETH</p>
</InfoBox>
<InfoBox>
  <h3 className="info-box-text">MY DEPOSITS</h3> {/* Updated label */}
  <p className="info-box-text">{Number(userDeposits).toFixed(3)} $ETH</p> {/* Limiting to 3 decimal places */}
</InfoBox>
<InfoBox>
  <h3 className="info-box-text">DEPOSIT COOLDOWN</h3>
  <p className="info-box-text">{depositCooldown} HOURS</p>
</InfoBox>
<InfoBox>
  <h3 className="info-box-text">ACTION COOLDOWN</h3>
  <p className="info-box-text">{actionCooldown} HOURS</p>
</InfoBox>
<InfoBox className="info-box max-payout">
  <h3 className="info-box-text">MAX PAYOUT REACHED?</h3>
  <p className="info-box-text">{maxPayoutReached ? "YES" : "NO"}</p>
</InfoBox>
<InfoBox className="info-box referral-tier">
  <h3 className="info-box-text">REFERRAL TIER</h3>
  <p className="info-box-text">
    {referralTier !== null ? (referralTier / 10).toFixed(1).replace(/\.0$/, '') : 'Loading...'}
  </p>
</InfoBox>
<p className="referral-info">
  EARN $ETH DIRECTLY INTO YOUR WALLET THROUGH OUR 1% TO 20% REFERRAL SYSTEM
</p>
        </InfoBoxContainer>
      </Modal>
    </div>
  );
};

export default Castle;