import React, { useState, useEffect } from "react";
import Modal from "../shared/Modal";
import { InfoBox, InfoBoxContainer } from "../../themes/global";
import potion1 from "../../assets/boost1.png";
import potion2 from "../../assets/boost2.png";
import circleLogoGif from "../../assets/circlelogo.gif"; // Import the GIF

const Tower = ({ closeModal }) => {
    const [userBoostLevel, setUserBoostLevel] = useState(0);
    const [canBuyBoost, setCanBuyBoost] = useState(false);
    const [applyForNftBoost, setApplyForNftBoost] = useState(false);
    const [loading, setLoading] = useState(true);
    const [level1, setLevel1] = useState(true);
    const [level1Cost, setLevel1Cost] = useState(true);
    const [level2, setLevel2] = useState(true);
    const [level2Cost, setLevel2Cost] = useState(true);
    const [showSparkle, setShowSparkle] = useState(false);
    const [tooltipMessage, setTooltipMessage] = useState(""); // State for tooltip message

    useEffect(() => {
        if (userBoostLevel > 0) {
            setShowSparkle(true);
            setTimeout(() => setShowSparkle(false), 1000); // Remove sparkle after animation
        }
    }, [userBoostLevel]);

    const updateStateValues = async () => {
        setLoading(true);
        if (window.hasValidWeb3Connection === true) {
            const userInfo = await window.getUserInfo();
            if (userInfo === undefined || userInfo === null) {
                return;
            }
            setUserBoostLevel(Number(userInfo._yieldLevelBoost));
            const earlyAccessEnabled = await window.contract.methods.addressHasEarlyAccess(window.currentAddr).call();
            const level1ETH = await window.contract.methods.YIELD_BOOST_LEVEL1_COST().call();
            setLevel1Cost(window.readableETH(level1ETH, 3));
            const level2ETH = await window.contract.methods.YIELD_BOOST_LEVEL2_COST().call();
            setLevel2Cost(window.readableETH(level2ETH, 2));
            const level1 = await window.contract.methods.YIELD_BOOST_LEVEL1().call();
            setLevel1(Number(level1) / 10);
            const level2 = await window.contract.methods.YIELD_BOOST_LEVEL2().call();
            setLevel2(Number(level2) / 10);

            if (Number(userInfo._yieldLevelBoost) >= 2) {
                setCanBuyBoost(false);
                setTooltipMessage("Max Boost Achieved");
            } else if (!earlyAccessEnabled) {
                setCanBuyBoost(false);
                setTooltipMessage("Fill out the form to prove your SCC NFT ownership to purchase a boost.");
                if (Number(userInfo._yieldLevelBoost) === 0 && BigInt(userInfo._totalWithdrawals) > BigInt(level1ETH)) {
                    setApplyForNftBoost(true);
                }
            } else {
                if (Number(userInfo._yieldLevelBoost) === 0 && BigInt(userInfo._totalWithdrawals) > BigInt(level1ETH)) {
                    setCanBuyBoost(true);
                    setTooltipMessage(""); // Clear tooltip if the button is available
                } else if (Number(userInfo._yieldLevelBoost) === 1 && BigInt(userInfo._totalWithdrawals) > (BigInt(level1ETH) + BigInt(level2ETH))) {
                    setCanBuyBoost(true);
                    setTooltipMessage(""); // Clear tooltip if the button is available
                } else {
                    setCanBuyBoost(false);
                    setTooltipMessage("You have not claimed enough $ETH to purchase the next boost.");
                }
            }
        }
        setLoading(false);
    };

    const close = () => {
        closeModal();
    };

    const applyForNft = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSdRMKPlxpxcX52xwDD43_I6k5JFYHXSWHY8tIEpooulJbMqeg/viewform");
    };

    const buyYieldLevelBoost = async () => {
        let levelCost = await window.contract.methods.YIELD_BOOST_LEVEL1_COST().call();
        if (userBoostLevel === 1) {
            levelCost = await window.contract.methods.YIELD_BOOST_LEVEL2_COST().call();
        }
        if (userBoostLevel >= 2) {
            alert("You have already reached the highest boost");
            return;
        }
        await window.buyYieldLevelBoost(userBoostLevel + 1, levelCost);
        await updateStateValues();
    };

    useEffect(() => {
        (async () => {
            await updateStateValues();
        })();
    }, []);

    return (
        <div>
            <div className="tower-gif-container">
                <img src={circleLogoGif} alt="Circle Logo GIF" className="tower-logo-gif" />
            </div>

            <Modal closeModal={close} title={<span className="tower-modal-title">Wizard&apos;s Tower</span>}>
                <InfoBoxContainer>
                    <InfoBox>
                    <h3 className="boost-level-text">Boost Level</h3>
                    <p className="boost-level-text">{userBoostLevel}</p>
                        {userBoostLevel === 1 && (
                            <p>{level1Cost} $ETH - {level1}% Yield Boost</p>
                        )}
                        {userBoostLevel === 2 && (
                            <p>{level2Cost} $ETH - {level2}% Yield Boost</p>
                        )}
                    </InfoBox>
                    {userBoostLevel === 1 && (
                        <InfoBox className={showSparkle ? 'sparkle-animation' : ''}>
                            <p>Brew of Bounty</p>
                            <img className="h80px" src={potion1} alt="Brew of Bounty" />
                        </InfoBox>
                    )}
                    {userBoostLevel === 2 && (
                        <InfoBox className={showSparkle ? 'sparkle-animation' : ''}>
                            <p>Tonic of Triumph</p>
                            <img className="h80px" src={potion2} alt="Tonic of Triumph" />
                        </InfoBox>
                    )}
                    {!applyForNftBoost && (
                        <button
                            disabled={!canBuyBoost || loading}
                            className="primary-button"
                            onClick={() => buyYieldLevelBoost()}
                            title={!canBuyBoost ? tooltipMessage : ""}
                        >
                            {userBoostLevel >= 2 ? "Max Boost Achieved" : "Buy Boost"}
                        </button>
                    )}
                    {applyForNftBoost && (
                        <button className="primary-button h80px" onClick={() => applyForNft()}>
                            Apply for Boost Potions
                        </button>
                    )}
                </InfoBoxContainer>

                <p style={{ marginTop: '20px', fontSize: '0.9em', color: '#888', textAlign: 'center' }}>
                Purchase Potions to Earn 10% to 30% yield boosts after claiming enough $ETH. Two levels, with more withdrawals required for higher boosts. SCC NFT needed. Whitelist Form available if withdrawal requirements are met.
                </p>
            </Modal>
        </div>
    );
};

export default Tower;