import React, { useEffect, useState } from "react";
import Modal from "../shared/Modal";
import { InfoBoxContainer, InfoBox } from "../../themes/global";
import taxImage from "../../assets/tax1.png"; // Import the image

const Portal = ({ closeModal }) => {
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [taxTier, setTaxTier] = useState(0);
  const [userRoiPercent, setUserRoiPercent] = useState(0);
  const [loading, setLoading] = useState(true);
  const [actionCooldown, setActionCooldown] = useState(0);

  const close = () => {
    window.clearAllIntervals();
    closeModal();
  };

  const withdraw = async () => {
    setLoading(true);
    await window.withdraw();
    await updateStateValues();
    setLoading(false);
  };

  const calculateActionCD = (lastAction, actionCoolDown) => {
    const now = Math.floor((new Date()).getTime() / 1000);
    let timeLeft = lastAction + actionCoolDown - now;
    timeLeft = (timeLeft < 0 ? 0 : timeLeft) / 60 / 60;
    timeLeft = Math.round(timeLeft * 100) / 100;
    console.log("User Action CD", timeLeft);
    return timeLeft;
  };

  const startActionCDCalculation = (lastAction, actionCoolDown) => {
    setInterval(() => {
      const timeLeft = calculateActionCD(lastAction, actionCoolDown);
      setActionCooldown(timeLeft);
    }, 1000);
  };

  const startSellCalculation = () => {
    setInterval(async () => {
      const rewardsInEth = await window.contract.methods.calculateSell(window.currentAddr).call();
      rewardsInEth && setWithdrawalAmount(window.readableETH(rewardsInEth, 5));
      console.log("calculate sell", window.readableETH(rewardsInEth, 5));
    }, 1000);
  };

  const updateStateValues = async () => {
    const userInfo = await window.getUserInfo();
    window.clearAllIntervals();
    if (userInfo === undefined || userInfo === null) {
      return;
    }
    if (window.currentAddr) {
      const userRoiPercent = await window.contract.methods.userRoiPercent(window.currentAddr).call();
      userRoiPercent && setUserRoiPercent(parseInt(userRoiPercent) / 1000);

      const rewardsInEth = await window.contract.methods.calculateSell(window.currentAddr).call();
      rewardsInEth && setWithdrawalAmount(window.readableETH(rewardsInEth, 5));

      const actionCoolDown = await window.contract.methods.ACTION_COOLDOWN().call();
      const timeLeft = calculateActionCD(parseInt(userInfo._lastAction), parseInt(actionCoolDown));
      setActionCooldown(timeLeft);
      startActionCDCalculation(parseInt(userInfo._lastAction), parseInt(actionCoolDown));
      startSellCalculation();
    }
    calcTaxTier();
  };

  const calcTaxTier = () => {
    if (userRoiPercent >= 50) {
      setTaxTier(10);
    }
    if (userRoiPercent >= 75) {
      setTaxTier(15);
    }
    if (userRoiPercent >= 100) {
      setTaxTier(20);
    }
    if (userRoiPercent >= 150) {
      setTaxTier(30);
    }
    if (userRoiPercent >= 200) {
      setTaxTier(40);
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      await updateStateValues();
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <Modal closeModal={close} title={<span className="portal-title">Tax Portal</span>}>
        <div className="p-b-10px portal-withdrawal-container">
          <div className="p-b-10px portal-withdrawal-amount">{withdrawalAmount} $ETH</div>
          <button
            disabled={loading || !window.hasValidWeb3Connection || actionCooldown > 0}
            className="portal-withdraw-button"
            onClick={() => withdraw()}
          >
            CLAIM
          </button>
        </div>
        <InfoBoxContainer>
          <InfoBox>
            <h3>WITHDRAWAL COOLDOWN</h3>
            <p>{actionCooldown} HOURS</p>
          </InfoBox>
          <InfoBox>
            <h3>ROI</h3>
            <p>{userRoiPercent} %</p>
          </InfoBox>
          <InfoBox>
            <h3>TAX TIER</h3>
            <p>{taxTier} %</p>
          </InfoBox>
          <div className="center-container">
            <div className="portal-roi-container">
              <p>{`50% ROI >= 10% Tax`}</p>
              <p>{`75% ROI >= 15% Tax`}</p>
              <p>{`100% ROI >= 20% Tax`}</p>
              <p>{`150% ROI >= 30% Tax`}</p>
              <p>{`200% ROI >= 40% Tax`}</p>
            </div>
            <img src={taxImage} alt="Tax Info" className="portal-tax-image" />
          </div>
        </InfoBoxContainer>
      </Modal>
    </div>
  );
};

export default Portal;