import React, { useEffect, useState } from 'react';
import ArbitrumLand from "./ArbitrumLand";
import Castle from "./elements/Castle";
import Dragons from "./elements/Dragons";
import Tower from "./elements/Tower";
import Portal from "./elements/Portal";
import Ufo from "./elements/Ufo"; // Import Ufo component
import {
  ImgOutline,
  IndexContainer
} from "../themes/global";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import UniswapDashboard from "./elements/UniswapDashboard"; // Import the UniswapDashboard component

const Index = () => {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [iframeHeight, setIframeHeight] = useState('205px'); // Initialize iframe height

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      // Adjust iframe height for mobile screens
      if (window.innerWidth <= 600) {
        setIframeHeight('300px'); // Set height to 300px for small screens
      } else {
        setIframeHeight('205px'); // Default height for larger screens
      }
    };

    // Set initial height based on current window size
    handleResize();

    window.addEventListener("resize", handleResize); // Listen to window resizing

    return () => window.removeEventListener("resize", handleResize); // Clean up on unmount
  }, []);

  const [showFooter, setShowFooter] = useState(true);
  const [showHeader, setShowHeader] = useState(true);

  const [showCastle, setShowCastle] = useState(false);
  const [showDragons, setShowDragons] = useState(false);
  const [showPortal, setShowPortal] = useState(false);
  const [showTower, setShowTower] = useState(false);
  const [showUfo, setShowUfo] = useState(false); // Add state for UFO

  const closeHeaderFooter = () => {
    if (windowSize.width < 1024) {
      return;
    }
    setShowHeader(false);
    setShowFooter(false);
  }
  const openHeaderFooter = () => {
    if (windowSize.width < 1024) {
      return;
    }
    setShowHeader(true);
    setShowFooter(true);
  }

  const handleCastleClick = () => {
    setShowCastle(!showCastle);
    closeHeaderFooter();
  }

  const closeCastle = () => {
    setShowCastle(false);
    openHeaderFooter();
  }

  const closeDragons = () => {
    setShowDragons(false);
    openHeaderFooter();
  }

  const handleDragonClick = () => {
    setShowDragons(!showDragons);
    closeHeaderFooter();
  }

  const handleMineClick = () => {
    window.open("https://polygonland.club?ref=0xfa71c8000ce627c30fdc50ae861f28fd692915a3", "_blank");
    closeHeaderFooter();
  }

  const handlePortalClick = () => {
    setShowPortal(!showPortal);
    closeHeaderFooter();
  }

  const closeTower = () => {
    setShowTower(false);
    openHeaderFooter();
  }

  const handleSpiderClick = () => {
    window.open("https://cryptomischief.io/scc-nft/", "_blank");
  }

  const handleTowerClick = () => {
    setShowTower(!showTower);
    closeHeaderFooter();
  }

  const handleUfoClick = () => {
    setShowUfo(!showUfo); // Toggle the UFO modal
    closeHeaderFooter();
  }

  const closePortal = () => {
    setShowPortal(false);
    openHeaderFooter();
  }

  const closeUfo = () => {
    setShowUfo(false);
    openHeaderFooter();
  }


  const onFooterClick = () => {
    setShowFooter(!showFooter);
  }

  const onHeaderClick = () => {
    if (windowSize.width < 1024) {
      return;
    }
    setShowHeader(!showHeader);
  }

  return (
    <div className="index">
      <IndexContainer>
        <Header
          cssClass={showHeader ? "header-opened" : "header-collapsed"}
          toggleState={onHeaderClick}
        />
        <ArbitrumLand
          onCastleClick={handleCastleClick}
          onDragonClick={handleDragonClick}
          onPortalClick={handlePortalClick}
          onTowerClick={handleTowerClick}
          onMineClick={handleMineClick}
          onSpiderClick={handleSpiderClick}
          onUfoClick={handleUfoClick} // Use the updated UFO handler
        />
        {showCastle ? <Castle closeModal={closeCastle} /> : null}
        {showDragons ? <Dragons closeModal={closeDragons} /> : null}
        {showTower ? <Tower closeModal={closeTower} /> : null}
        {showPortal ? <Portal closeModal={closePortal} /> : null}
        {showUfo ? <Ufo closeModal={closeUfo} /> : null} {/* Conditionally render the UFO modal */}

        <div className="button-container"> {/* New Flexbox container */}
          <ImgOutline onClick={handleCastleClick}>
            <div className="infobox-title">
              <p>Castle Arb</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handleDragonClick}>
            <div className="infobox-title">
              <p>Compound</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handlePortalClick}>
            <div className="infobox-title">
              <p>Claim</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handleTowerClick}>
            <div className="infobox-title">
              <p>Boost APR</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handleMineClick}>
            <div className="infobox-title">
              <p>Polygon Land</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={handleSpiderClick} display="none">
            <div className="infobox-title">
              <p>Buy NFT</p>
            </div>
          </ImgOutline>
          <ImgOutline onClick={() => window.open('https://arbiscan.io/address/0xafBD3dBfA79d1B271E8dE61161017FE1265241d4', '_blank')} display="none">
            <div className="infobox-title">
             <p>Contract</p>
             </div>
          </ImgOutline>
          <ImgOutline onClick={handleUfoClick}>
            <div className="infobox-title">
              <p>Referral Dashboard</p>
            </div>
          </ImgOutline>
        </div>

        <h2 style={{ textAlign: 'center', marginTop: '100px', color: '#fff' }}>
          Get ARBITRUM ETH with our Cross Chain Bridge
        </h2>

        {/* Insert the iframe widget here */}
        <div className="iframe-container">
  <iframe 
    id='iframe-widget' 
    src='https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=true&amount=1&amountFiat=1500&backgroundColor=0D0907&darkMode=true&from=bnbbsc&fromFiat=eur&horizontal=true&isFiat&lang=en-US&link_id=66c6cd9a2664ca&locales=true&logo=false&primaryColor=32174D&to=etharb&toFiat=eth&toTheMoon=true'>
  </iframe>
  <script defer type='text/javascript' src='https://changenow.io/embeds/exchange-widget/v2/stepper-connector.js'></script>
</div>

        {/* Uniswap Dashboard placed here */}
        <div style={{ marginTop: '70px' }}>
          <UniswapDashboard /> {/* Render the Uniswap dashboard component */}
        </div>

        {/* Embed YouTube video */}
        <div className="youtube-video" style={{ marginTop: '80px' }}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/jwn7PC7pvKI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <Footer
          cssClass={showFooter ? "footer-opened" : "footer-collapsed"}
          toggleState={onFooterClick}
          handleDragonClick={handleDragonClick}
          handlePortalClick={handlePortalClick}
          onCastleClick={handleCastleClick}
        />
      </IndexContainer>
    </div>
  );
};

export default Index;